.jobs-page {
  margin: 0 0.5rem 1rem;
  @include mobile {
    overflow: auto;
  }


  .page-title {
    margin-right: 100px;
    margin-bottom: 0;
    position: relative;
    bottom: -5px;
  }

  .tabs {
    overflow: visible;

    ul {
      border-bottom-color: $primary;
    }

    li {

      a {
        margin: 0 0.5rem;
        padding-bottom: 0;
        background: $white;
        border-width: 5px !important;
        border-bottom-color: transparent;
        position: relative;
        top: 5px;
        min-width: 180px; //ne ugráljon a szélesség, ha aktív lesz a tab
        &.job-state-all {
          background: transparent;
        }

        &.is-active {
          font-weight: $weight-bold;
        }
      }
    }
  }

  .jobs-list {

  }
}

.job-details-page {

  .help {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    font-weight: $weight-normal;
  }

  .data-highlighter {
    margin: 0 0.5rem;

    .tooltip:hover::before {
      margin-left: 1rem;
    }

    &.payment-method {
      flex-shrink: 0;
    }

    &-title {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: $weight-light;

    }

    &-data {
      font-size: 1.3rem;
      font-weight: $weight-semibold;
    }
  }


  .profile {
    display: flex;
    align-items: center;

    &-avatar {
      $imageSize: 80px;
      color: $color1;
      width: $imageSize;
      height: $imageSize;
      @include mobile {
        width: 100px;
        height: 100px;
        margin-right: 1rem;
      }

      img {
        border-radius: 50%;
        width: $imageSize;
        height: $imageSize;
        @include mobile {
          width: 100px;
          height: 100px;
          margin-right: 1rem;
        }
      }
    }

    &-details {
      text-align: center;
      font-size: 1.3rem;
      margin-left: 1rem;

      .is-small {
        font-size: 1rem;
      }

      .rating {
        .icon {
          width: 16px;
          height: 16px;
        }
      }

      .job-count {
        font-size: $size-small;
      }

    }

    &-header {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: 1.3rem;
      align-items: center;
    }
  }


  .job-details {

  }

  .job-address {
    display: flex;
    flex: 1;
    width: 100%;
  }

  .job-address-text {
    .icon {
      margin-left: 0.5rem;
    }
  }

}

.job-page {
  > .buttons {
    padding-top: 1rem;
  }
}

.job-valuation-form {
  .field {
    align-items: center;
  }

  .field-label {
    min-width: 255px;
  }
}

.action-form-wrapper {
  .field .control {
    max-width: 500px;
  }
}


.my-job-requests {
  margin: 0 0.5rem;
  @include mobile {
    overflow: auto;
  }
}