footer {
  @include desktop {
    min-height: 300px;
  }
  background: $color1;
  color: $white;
  margin-top: 2rem;

  @include mobile {
    text-align: center;
  }

  a {
    color: $white;

    &:hover {
      color: $color2;
    }
  }

  .follow-us {
    display: flex;
    align-items: flex-start;
    @include mobile {
      justify-content: center;
    }
  }

  .social-icon {
    margin-left: 5px;
    width: 28px;
    height: 28px;
  }

  .icons-license {
    display: flex;
    justify-content: center;
    font-size: 10px;
    a {
      margin-bottom:1rem;
    }
  }

}