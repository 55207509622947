.create-job {

  .control {
  }

  .category-selector {
    display: flex;
    flex-wrap: wrap;

    .main-category {
      display: flex;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
      background: $white-ter;

      .sub-categories {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .sub-category {
          background: $primary;
          color: $primary-invert;
          font-weight: $weight-semibold;
          padding: 0.5rem;
          margin: 0.5rem;
          border-radius: 5px;
          cursor: pointer;
          text-transform: capitalize;
        }
      }
    }
  }

  .job-start-date {
    p.help.is-danger {
      max-width: 220px;
    }
  }

  .validation-errors {
    margin-bottom: 1rem;

    .validation-error {
      color: $danger;
    }
  }

  .start-time-label {
    visibility: hidden;
    @include mobile {
      display: none;
    }
  }

  .personal-data-form-selector {
    .tabs {
      ul {
        flex-wrap: wrap;
        flex-shrink: initial;

        li {
          margin: 5px 0;

          &.is-active {
            a {
              background-color: $color2;
              border-color: $color2;
            }
          }

          font-weight: $weight-bold;
        }
      }
    }
  }
}


.job-confirmation {
  .header {
    display: flex;
    flex-wrap: wrap;

    .profile {
      @include mobile {
        display: flex;
        align-items: center;
      }


      &-avatar {
        color: $color1;
        width: 200px;
        height: 200px;
        @include mobile {
          width: 100px;
          height: 100px;
          margin-right: 1rem;
        }

        margin-bottom: 1rem;

        img {
          border-radius: 50%;
          width: 200px;
          height: 200px;
          @include mobile {
            width: 100px;
            height: 100px;
            margin-right: 1rem;
          }
        }
      }

      &-details {
        text-align: center;
        font-size: 1.5rem;

        .is-small {
          font-size: 1rem;
        }
      }

      &-header {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 1.5rem;
      }
    }

    .job-details {
      @include desktop {
        margin-left: 2rem;
      }
    }
  }

}

.data-highlighter-container {
  display: flex;
  margin: 0.5rem 0;
  @include mobile {
    flex-direction: column;
  }

  .data-highlighter {
    &:not(:first-child) {
      @include tablet {
        //border-left:2px solid;
        padding-left: 1rem;
      }
    }
  }
}

.wizard-buttons {
  margin-bottom: 1rem;
}

.payment-method-selection {

  .payment-method {
    .field {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .computed-price {
      font-weight: $weight-bold;
    }
  }
}

.login-or-register {
  display: flex;
  flex-direction: column;
  align-items: center;

  .registration {

  }
}