.finance-page {

  .finance-summary {
    display: flex;
    justify-content: flex-end;
    font-size: $size-large;
    border-bottom: 1px solid $primary;
    margin-bottom: 0.5rem;

    .total-income {
      font-weight: $weight-bold;
      margin-left:0.5rem;
    }
  }

  .income-by-month {
    display: flex;
    background: $grey-lighter;
    .month {
      display: flex;
      flex:1;
      flex-direction: column;
      padding: 5px 5px 10px 5px;

      &:not(:last-child){
        border-right: 3px solid $white;
      }

      &-name {
        text-transform: capitalize;
        margin-bottom: 5px;
      }

      &-income {
        font-weight: $weight-bold;
      }
    }
  }

  .jobs-list {
    margin-top:3rem;
  }
}