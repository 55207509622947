$header-height:110px;
$header-mobile-height:80px;

@import "components/all";
@import "pages/all";


.app-content {
  min-height: calc(100vh - #{$header-height});
}

.icon,.svg-icon {
  $icon-size:16px;
  width: $icon-size;
  height: $icon-size;
  cursor: pointer;

  svg {
    width: $icon-size;
    height: $icon-size;
  }

  &.is-left {
    margin-right: 5px;
  }
  &.is-right {
    margin-left: 5px;
  }
}

.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}

button {
  a {
    color: inherit;
  }
}

.button {
  font-weight: $weight-bold;
}

.root {
  position: relative;
  top: $header-height;
  @include mobile {
    top: $header-mobile-height;
  }
}

.page {
  padding-top: 10px;
  margin-bottom: 2rem;
  @include mobile {
    margin:auto 0.5rem;
  }
  &.no-margin {
    margin: 0;
  }

  &.no-padding {
    padding: 0;
  }
}

.buttons {
}

.error {
  display: flex;
  justify-content: center;
  color: $danger;
  font-weight: $weight-bold;
  margin: 1rem 0;
}

.section-title {
  border-bottom: 1px solid $primary;
  padding-bottom: 0.5rem;
  text-transform: capitalize;
}

.section {
  margin-bottom: 1rem;
}

.action-form-wrapper {
  padding: 1rem 0;
  margin-bottom: 1rem;
  background: aliceblue;
}

.is-blurred {
  filter: blur(4px);
  user-select: none;
}


.stroked {
  overflow: hidden;
  text-align: center;
  position: relative;
  margin: 1rem 0;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top:50%;
    border-bottom: 2px solid $grey-lighter;
    left: 0;
    width: 40%;
  }
  &::after {
    content: "";
    position: absolute;
    top:50%;
    border-bottom: 2px solid $grey-lighter;
    right: 0;
    width: 40%;
  }

}


.top-margin {
  margin-top: 1rem;
}

.is-text-centered {
  text-align: center;
}

.is-underlined {
  text-decoration: underline;
}


.eu-logo {
  position: fixed;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: 1000;
  height: 150px;
  @include mobile {
    display: none;
  }


}

img.eu-logo {

}

.eu-logo-container {
  display: flex;
  justify-content: flex-end;
  .eu-logo-header {
    background: $white;
    height: 150px;
    @include desktop {
      display: none;
    }
  }
}


.cc-window.cc-floating {
  padding: 0.5rem 0.8rem;
}

.cc-message {
  margin-bottom: 0.5rem !important;
}