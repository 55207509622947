.home-page {
  margin: 1rem 0.5rem;

  .notifications {
    width: 100%;

    table {
      td {
        border: none;
      }
    }

    &-link {
      a {
        display: flex;
        align-items: center;
        color: $text;
        text-decoration: none;

        .icon {
          fill: $primary;
        }
      }
    }

  }

  .news-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    &-title {
      text-align: center;
      margin: 1rem 0;
    }

    &-content {
      margin: 0 2.5rem;
      @include mobile {
        margin: 0 1rem;
      }
    }

    &-button {
      margin-top: 1rem;
    }

    &-image {
      width: 300px;
      height: 200px;
    }
  }


}

.news-page {
  .news-image {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }
}