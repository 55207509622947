table.expandable-list {
  tr:not(.expanded) {
    background: $grey-lighter;

    td {
      border: 3px solid $white;
      border-right: none;

      &:last-child {
        border-left: none;
      }

      &.action {
        text-align: center;

        button {

        }
      }
    }
  }

  .expanded {
    .form {
      margin: 1rem 0;
    }
  }

}

.list-comp {
  .table {
    margin-bottom: 1rem;
  }

  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
}