$navbar-item-img-max-height:initial; //a logo nagyobb, mint 1.75rem

$color1: #1c2d38;
$color2: #3ec49d;
$color3:#19647E;

$body-background-color:#ffffff;
$primary:$color2;
$primary-invert:$color1;
$section-padding:0;
$success:$primary;

$navbar-burger-color:$body-background-color;


@import "~bulma";
@import "~bulma-o-steps/bulma-steps";
@import "~bulma-tooltip";
@import "~bulma-checkradio";
@import "sass/style";