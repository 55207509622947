.my-categories-list {
  @include mobile {
    overflow: auto;
  }
  .buttons {
    padding-top: 1rem;
  }

  .modal-content {
    overflow: visible;
  }
}