.date-field {
  position: relative;
  .icon  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}


.field {
  .label {
    display: flex;
    align-items: center;
    .tooltip::before {
      min-width: 150px;
    }
  }
}