.calendar {
  width: 257px;

  @include mobile {
    width: 100%;
    max-width: 350px;
  }

  $cellWidth: 35px;
  $cellHeight: 35px;
  margin: 0 auto;
  background: $white;

  a {
    color:$white;
  }

  &-days-view {
    min-height: 240px; //ne ugráljon, ha vmelyik hónapban kevesebb hetet mutat
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    button {
      background: inherit;
      border: none;
      padding: 5px 0;
      width: 2rem;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }

  .calendar-header-center {
    cursor: pointer;
    text-transform: capitalize;
    font-weight: $weight-bold;
  }

  .calendar-selector-view {
    &-content {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      &-item {
        width: 33%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: capitalize;
        padding: 0.5rem 0;

        &:hover {
          background: $white-ter;
        }

        &.selected {
          background: $grey;
          color: $white;

          &:hover {
            background: $grey !important;
          }
        }
      }
    }
  }


  &-days {
    display: flex;
    justify-content: space-between;

    > div {
      width: $cellWidth;
      text-align: center;
      color: $grey;
    }
  }

  &-week {
    display: flex;
    justify-content: space-between;
  }

  &-day {
    width: $cellWidth;
    height: $cellHeight;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;

    &.is-disabled {
      color: $grey-lighter;
    }

    &.current {
      border: 1px solid $primary;
      border-radius: 50%;
    }

    &.selected {
      background: $grey;
      color: $white;

      &:hover {
        background: $grey !important;
      }
    }


  }

  .cal-icon {
    width: 1rem;
    height: 1rem;
  }

  .cal-icon.left {
    //margin-left: 2rem;
    transform: rotate(180deg);
  }

  .cal-icon.right {
    // margin-right: 2rem;
  }

  .has-event {
    background: $primary;
    cursor: pointer;
  }

  .date-picker & {
    .calendar-day {
      cursor: pointer;

      &:hover {
        background: $white-ter;
      }
    }
  }

}

.date-picker {
  z-index: 1000;
  position: absolute;
}