.login-page {
  max-width: 350px;
  margin: auto;
  .content {
    margin: 1rem;
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin: 1rem 0;

    span {
      width: 100%;
    }

    button {
      height: 42px;
      justify-content: center;
      font-weight: $weight-bold;
      text-transform: uppercase;
      width: 100%;
      margin-right:0 !important;

      &.fb-login {
        background: #4c69ba;
        width: 100%;
        color: white;
      }

      &.google-login {
        span {
          font-weight: $weight-bold !important;
        }

        .svg-icon {
          width: 18px;
          height: 18px;
        }

      }
    }
  }

}