.messages-badge {
  width: 24px;
  height: 24px;
  color:$primary;
  cursor: pointer;
  .text {
    color:$primary-invert;
    font-size: 0.6rem;
  }
}

.rating {
  display: flex;
  .icon {
    margin:0 2px;
    width: 25px;
    height: 25px;
    color: $primary;
    svg {

    }

  }
  
  &.disabled {
    .icon {
      cursor: initial;
    }
  }
}

.loading-indicator {
  display: flex;
  justify-content: center;
  margin:1rem 0;
  svg {
    fill:$primary;
  }

}


.google-maps {
  margin-top: 1rem;
}

.msg {
  font-weight: $weight-semibold;
  text-align: center;
  margin:1rem 0;
}