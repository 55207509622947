.availability {
  margin: 0 0.5rem;
  overflow-x: auto;

  .table {
    thead th {
      text-align: center;
    }

    tbody tr td:first-child {
      min-width: 80px;
    }
  }

  &-period {
    min-width: 52px;
    cursor: pointer;

    &:hover {
    }

    &.selected {
      background: $primary;
    }
  }
}
