.app-notification {

  z-index: 9999999;
  position: absolute;
  left: 50%;

  &-items {
    position: relative;
    left:-50%;
  }

  &-item {
    cursor: pointer;
    min-width: 300px;
    background: aliceblue;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $weight-semibold;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 1rem 0;

    &.is-error {
      background: $danger;
      color:$white;
    }
  }

}