.home-page-public {
  margin-top: -10px;
  color: $black;
  font-size: 14px;

  h1 {
    font-size: 2.25rem;
    font-weight: $weight-bold;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: $weight-bold;
  }

  p {
    margin-bottom: 1rem;
  }


  .book-now-section {
    background: $color1;
    color: $white;
    min-height: 100vh;

    .marketing {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .booking-steps {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 2rem;

      .booking-step {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        position: relative;

        .step-name {
          text-align: center;
          font-size: 0.8rem;
        }

        .arrow {
          position: absolute;
          right: 0;
          top: 40%;
        }

        @include mobile {
          .step-name {
            font-size: 0.6rem;
          }
          .step-img {
            width: 50px;
            height: 50px;
          }
        }

      }
    }

    .main-categories {
      width: 95%;
      margin: 2rem auto;

      .category-container {
        display: flex;
        justify-content: center;
      }

      .category {
        flex:1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .categories {
          width: 200px;
        }

        .category-name {
          font-size: $size-medium;
          font-weight: $weight-semibold;
        }

        a {
          color:$white;
          &:hover {
            color:$color2;
          }
        }
      }

      .other-category {
        margin-top:1.5rem
      }
    }
  }

  .reasons {
    margin-top: 1rem;
    .columns {
      margin: 0;
    }

    margin-bottom: 1rem;

    .choose-us {
      color: $primary;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1rem;
      padding-top:2rem;
    }

    .reason {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-bottom:2rem;

      &-title {
        font-size: 2rem;
        font-weight: $weight-bold;
      }
    }

  }


  .book-now {
    background: $color1;
    color: $white;
    padding: 1rem 0;

    a {
      margin: 0
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;

      a {
        display: flex;
        justify-content: center;
        flex: 1;
        margin: 0.5rem 0;
      }
    }
  }

  .about-us {
    margin: 2rem 0.5rem;

    h1 {
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  .section-with-img {
    color: $black;
    background-color: #f6f7f9;

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;


      h1 {
        margin-bottom: 1rem;
        text-align: left;
      }

      img.left {
        // clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
      }

      img.right {
        // clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
      }

      div {
        flex: 1;
        margin: 1rem;
      }
    }
  }

  footer {
    background: $color1;
    color: $white;
    padding: 2rem 0.5rem;
  }

}