.messages-page {
  margin: 1rem 0.5rem 1rem 0.5rem;

  @include mobile {
    overflow: auto;
  }

  .page-title {
    border-bottom: 1px solid $primary;
  }

  .messages-badge {
    margin-right: 0.7rem;
  }

  .category-and-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
  }

}

.messages-controls {
  padding-top:2rem;
  border-top: 2px solid $primary;
  display: flex;
  justify-content: flex-end;
  .field {
    width: 70%;
  }
  .buttons {
    margin-left: 2rem;
  }

  .help {
    min-height: 1.2rem;
  }
}

.job-message {
  display: flex;
  flex: 1;
  $avatarSize: 64px;
  $cornerSize: 16px;
  width: calc(100% - #{$avatarSize + $cornerSize});
  margin: 1rem 0;

  &.flipped {
    margin-left: $avatarSize + $cornerSize;
  }

  &-avatar-container {
    display: flex;
    align-items: flex-end;
  }

  &-avatar {
    background: aliceblue;
    width: $avatarSize;
    height: $avatarSize;
    border-radius: 50%;
    background: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: $weight-semibold;
    color: $primary-invert;
    margin-left: 1rem;
    position: absolute;
    right: -($cornerSize + $avatarSize);

    .flipped & {
      margin-left: 0;
      margin-right: 1rem;
      right: initial;
      left: -($cornerSize + $avatarSize);
    }

    bottom: 0;
  }

  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: $cornerSize + $avatarSize;
    .flipped & {
      margin-left: 0;
      margin-right: $cornerSize + $avatarSize;
    }
    @include mobile {
      margin-left:0 !important;
      margin-right: 0 !important;
    }
  }

  &-msg {
    background: $primary;
    color: $primary-invert;
    padding: 1rem 2rem;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    position: relative;
    min-height: $avatarSize;


    .flipped & {
      background: $grey-lighter;
      color: $black;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 0;
    }

    .corner {
      fill: $primary;
      position: absolute;
      right: -$cornerSize;
      bottom: 0;

      .flipped & {
        fill: $grey-lighter;
        right: initial;
        left: -$cornerSize;
        transform: scaleX(-1);
      }

    }
  }

  &-date {
    display: flex;
    justify-content: flex-end;
    color: $grey;
    margin:0 1rem;
    .flipped & {
      justify-content: flex-start;
    }
  }


}