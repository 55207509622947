.profile-page {

  $avatar-size: 300px;
  margin: 0 0.5rem;

  .avatar {
    margin: auto;
    cursor: pointer;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: 50%;
    background-color: $grey;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .file {

      .file-label {
        width: $avatar-size;
        height: $avatar-size;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    img {
      width: $avatar-size;
      height: $avatar-size;
    }
  }
}