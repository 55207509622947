
.signup-method-selector {
  max-width: 350px;
  margin: auto;
  .content {
    margin: 0 0.5rem;
  }

}

.signup-page {


}