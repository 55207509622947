$jobStateConfig: 'applied' #ec9063,
'finished' #cbec63,
'started' #63d1ec,
'applied' #ec9063,
'expired' #ff0000;
// TODO: move to _settings.scss

@each $tuple in $jobStateConfig {
  .job-state-#{nth($tuple, 1)} {
    background: #{nth($tuple, 2)};
    border-bottom-color: #{nth($tuple, 2)} !important;
  }
}

.job-state {
  display: inline-block;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.avatar {
  $imageSize:64px;
  width: $imageSize;
  height: $imageSize;
  img,.img {
    width: $imageSize;
    height: $imageSize;
    border-radius: $imageSize;
    background: $primary;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: $weight-semibold;
    color: $primary-invert;
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  font-size: $size-medium;
}