.navbar {
  $borderWidth: 5px;
  height: $header-height;
  background: url("../../images/background.png");
  @include desktop {
    padding-bottom: 2rem;
  }

  @include mobile {
    height: $header-mobile-height;
    .hide-menu {
      display: none;
    }
  }

  .navbar-menu {
    @include touch {
      padding: 0;
    }
  }

  .navbar-start {
    background: $color1;
    margin-right: 0;
    flex:1;
    justify-content: center;
  }

  .navbar-item {
    color: $white;
    cursor: pointer;
    @include desktop {
      align-items: flex-end;
      padding-bottom: 0;
      position: relative;
      bottom: -$borderWidth - 1;
      border-bottom: 5px solid transparent;
    }

    &.active {
      border-color: $primary;
      color: $white;
    }
    &:hover {
      background: transparent;
      color:$primary;
    }

    .book-now {
      background: $primary;
      padding: 0 4px;
      color: $primary-invert;
    }
  }

  .navbar-brand {
    padding-bottom: 10px;
    cursor: pointer;
    .navbar-item {
      border: none;
    }

  }

  .menu-container {
    border-bottom: 1px solid $primary;
  }

  $avatarSize: 64px;

  .avatar-container {

    background: $color1;
    display: flex;
    align-items: center;
    position: relative;
    bottom: -$avatarSize / 2;
    cursor: pointer;

    .user-name {
      padding-left: 0.7rem;
    }
    .avatar .img{
      width: $avatarSize;
      height: $avatarSize;
      background: $primary;
      border-radius: 50%;
    }
  }

  .navbar-end {
    background: $color1;

    &.navbar-item {
      &:hover {
        background: currentColor;
      }
    }

    .navbar-dropdown {
      background: #1c2d38;
      border-top: none;
      top:97px;

      a.navbar-item:hover {
        background: $primary;
        color: $primary-invert;
      }
    }
  }

  .navbar-burger {
    top:25px;
    .bars {
      width: 24px;
      height: 24px;
    }
    &:hover {
      color:$white;
    }
  }

  .login-user-icon {
    margin-right: 5px;
    margin-bottom: 5px;
  }

}